import { useClassName } from '@/styles/MoreEpisodes';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'section' });
const Headline = GenericComponent({ as: 'h2', styles: { key: 'headline' } });

export const MoreEpisodes = Object.assign(Base, {
  Headline,
});
