import { propClassName } from '@/styles/AuthorDetails';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn, withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import { useAuthorDetailsContext } from './AuthorDetails';

export interface AuthorDetailsCaptionProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const AuthorDetailsCaptionComponent: Component<AuthorDetailsCaptionProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const context = useAuthorDetailsContext({ colors, size, variant });

  const colorsClassName = propClassName('caption_colors', context.colors ?? context.variant);
  const sizeClassName = propClassName('caption_size', context.size ?? context.variant);
  const variantClassName = propClassName('caption_variant', context.variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </div>
  );
};
