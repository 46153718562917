/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandalonePagination as ResolvedStandalonePagination } from 'sites/svenskdam/components/standalone/Pagination';

export const Pagination = ResolvedStandalonePagination;

export type PaginationProps = PropsFromComponent<typeof Pagination>;

export const PaginationWith = (extras: DynamicStandaloneExtras): typeof Pagination => {
    return function Pagination(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Pagination');
        return <ResolvedStandalonePagination {...mergeProps({ options: { theme } }, props)} />;
    }
}