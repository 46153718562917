import { StandaloneMoreEpisodes } from 'base/components/MoreEpisodes';

export const SvenskdamStandaloneMoreEpisodes: typeof StandaloneMoreEpisodes = (props) => (
  <StandaloneMoreEpisodes
    options={{
      $teaser: {
        $content: {
          className: '!px-0',
        },
      },
    }}
    {...props}
  />
);

export default SvenskdamStandaloneMoreEpisodes;
