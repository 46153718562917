import { useStableProps } from '@/hooks/useStable';
import StandalonePagination from 'base/components/Pagination';

export const SvenskdamStandalonePagination: typeof StandalonePagination = (props) => {
  const stableProps = useStableProps(
    {
      options: { $arrow: { className: 'text-gray-100 [&_path]:fill-gray-700' } },
    },
    props
  );

  return <StandalonePagination {...stableProps} />;
};

export default SvenskdamStandalonePagination;
