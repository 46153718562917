/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneListGrid as ResolvedStandaloneListGrid } from 'base/components/standalone/ListGrid';

export const ListGrid = ResolvedStandaloneListGrid;

export type ListGridProps = PropsFromComponent<typeof ListGrid>;

export const ListGridWith = (extras: DynamicStandaloneExtras): typeof ListGrid => {
    return function ListGrid(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ListGrid');
        return <ResolvedStandaloneListGrid {...mergeProps({ options: { theme } }, props)} />;
    }
}