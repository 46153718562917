import { JwVideoTeaser, JwVideoTeaserProps } from '@/components/JwVideo/Teaser';
import { List, ListProps } from '@/components/List';
import { componentTheme as jwVideoTeaserComponentTheme } from '@/styles/JwVideoTeaser';
import { ClassNameProvider } from '@/styles/MoreEpisodes';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { VideoShowPlaylistPage } from 'lib/data/video';
import { ReactNode } from 'react';
import { MoreEpisodes } from './MoreEpisodes';

interface ExtendedOptions {
  $list?: ListProps['options'];
  $teaser?: JwVideoTeaserProps['options'];
}

export interface StandaloneMoreEpisodesProps extends StandaloneComponentProps<typeof MoreEpisodes, ExtendedOptions> {
  headline?: ReactNode;
  episodes?: VideoShowPlaylistPage['relatedMedia'];
}

export const StandaloneMoreEpisodes: StandaloneComponent<StandaloneMoreEpisodesProps> = ({
  episodes,
  headline,
  options,
  ...props
}) => {
  const { $list, $teaser, ...$base } = options ?? {};
  return (
    <ClassNameProvider value={$base}>
      <MoreEpisodes {...$base} {...props}>
        {headline && <MoreEpisodes.Headline>{headline}</MoreEpisodes.Headline>}
        {episodes && (
          <List
            options={mergeOptions(
              {
                variant: 'none',
              },
              $list,
            )}
            items={episodes.map((media) => (
              <JwVideoTeaser
                key={media.mediaId}
                mediaId={media.mediaId}
                thumbnail={media.image}
                isStillImage={false}
                title={media.title}
                tag={media.prominentTag}
                teaserLink={media.url}
                options={mergeOptions(
                  {
                    theme: jwVideoTeaserComponentTheme('video_pages'),
                    variant: 'horizontal',
                    $media: {
                      $video: {
                        playOnHover: true,
                      },
                    },
                    $link: {
                      target: '_self',
                    },
                    $content: {
                      colors: 'default',
                    },
                  },
                  $teaser,
                )}
              />
            ))}
          />
        )}
      </MoreEpisodes>
    </ClassNameProvider>
  );
};

export default StandaloneMoreEpisodes;
