import { Icon, IconProps } from '@/components/Icon';
import { Link, LinkProps } from '@/components/Link';
import { ClassNameProvider } from '@/styles/Pagination';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { Pagination } from './Pagination';

interface ExtendedOptions {
  $arrow?: IconProps['options'];
  $arrowLink?: LinkProps['options'];
  $buttonLink?: LinkProps['options'];
}
export interface StandalonePaginationProps extends StandaloneComponentProps<typeof Pagination, ExtendedOptions> {
  total: number;
  page: number;
  limit: number;

  iconNext?: ReactNode;
  iconPrevious?: ReactNode;
  steps?: number;
}

export const StandalonePagination: StandaloneComponent<StandalonePaginationProps> = ({
  total,
  limit,
  iconNext,
  iconPrevious,
  page,
  steps = 3,
  options,
  ...props
}) => {
  const { $arrow, $arrowLink, $buttonLink, $button, ...$base } = options ?? {};
  const { asPath, replace } = useRouter();

  const basePath = page !== 1 || asPath.includes(String(page)) ? asPath.split('/').slice(0, -1).join('/') : asPath;

  useEffect(() => {
    if (page === 1 && asPath !== basePath) {
      replace(basePath);
    }
  }, [asPath, basePath, page]);

  const pageLength = Math.floor(total / Math.max(limit, 1));

  const stepsArray = Array.from({ length: steps });

  const paginationSteps =
    page === 1
      ? stepsArray.map((_, index) => index + 1)
      : page >= pageLength - Math.floor(steps / 2)
        ? stepsArray.map((_, index) => pageLength - steps + index + 1)
        : stepsArray.map((_, index) => page - Math.floor(steps / 2) + index);

  const leftArrowHref = page === 2 ? basePath : `${basePath}/${page - 1}`;

  const rightArrowHref = `${basePath}/${page + 1}`;

  return (
    <ClassNameProvider value={$base}>
      <Pagination {...$base} {...props}>
        <Link
          href={leftArrowHref}
          options={mergeOptions({ className: page === 1 ? 'pointer-events-none' : '' }, $arrowLink)}
          content={
            iconPrevious ?? (
              <Icon
                name="sliderForwardArrow"
                options={mergeOptions({ className: 'h-7 w-7 p-2 rotate-180 bg-gray-100 rounded-full' }, $arrow)}
              />
            )
          }
        />
        {paginationSteps.map((step) => (
          <Link
            key={step}
            href={step === 1 ? basePath : `${basePath}/${step}`}
            content={
              <Pagination.Button data-active={step === page} {...$button}>
                {step}
              </Pagination.Button>
            }
            {...$buttonLink}
          />
        ))}
        <Link
          href={rightArrowHref}
          options={mergeOptions({ className: page === pageLength ? 'pointer-events-none' : '' }, $arrowLink)}
          content={
            iconNext ?? (
              <Icon
                name="sliderForwardArrow"
                options={mergeOptions({ className: 'h-7 w-7 p-2 bg-gray-100 rounded-full' }, $arrow)}
              />
            )
          }
        />
      </Pagination>
    </ClassNameProvider>
  );
};

export default StandalonePagination;
