/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneAuthorDetails as ResolvedStandaloneAuthorDetails } from 'base/components/standalone/AuthorDetails';

export const AuthorDetails = ResolvedStandaloneAuthorDetails;

export type AuthorDetailsProps = PropsFromComponent<typeof AuthorDetails>;

export const AuthorDetailsWith = (extras: DynamicStandaloneExtras): typeof AuthorDetails => {
    return function AuthorDetails(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AuthorDetails');
        return <ResolvedStandaloneAuthorDetails {...mergeProps({ options: { theme } }, props)} />;
    }
}