/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneMoreEpisodes as ResolvedStandaloneMoreEpisodes } from 'sites/svenskdam/components/standalone/MoreEpisodes';

export const MoreEpisodes = ResolvedStandaloneMoreEpisodes;

export type MoreEpisodesProps = PropsFromComponent<typeof MoreEpisodes>;

export const MoreEpisodesWith = (extras: DynamicStandaloneExtras): typeof MoreEpisodes => {
    return function MoreEpisodes(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'MoreEpisodes');
        return <ResolvedStandaloneMoreEpisodes {...mergeProps({ options: { theme } }, props)} />;
    }
}