import { Button, ButtonProps } from '@/components/Button';
import { Icon, IconProps } from '@/components/Icon';
import { Tag, TagProps } from '@/components/Tag';
import { ClassNameProvider } from '@/styles/ShowHero';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import NextImage, { ImageProps } from 'next/image';
import { ReactNode } from 'react';
import { ShowHero, ShowHeroSubcomponentProps } from './ShowHero';

interface ExtendedOptions {
  $category?: ShowHeroSubcomponentProps['Content']['Category'] & {
    $tag?: TagProps['options'];
  };
  $button?: ButtonProps['options'];
  $nextImage?: ImageProps;
  $playIcon?: IconProps['options'];
}

export interface StandaloneShowHeroProps extends StandaloneComponentProps<typeof ShowHero, ExtendedOptions> {
  headline?: ReactNode;
  description?: ReactNode;
  category?: {
    value?: ReactNode;
    url?: string;
  };
  button?: {
    value?: ReactNode;
    url?: string;
  };
  image?: { src?: string; alt?: string };
}

export const StandaloneShowHero: StandaloneComponent<StandaloneShowHeroProps> = ({
  headline,
  description,
  category,
  button,
  options,
  image,
  ...props
}) => {
  const { $content, $image, $category: categoryProps, $button, $nextImage, $playIcon, ...$base } = options ?? {};
  const { $tag: $categoryTag, ...$category } = categoryProps ?? {};

  const { $description, $button: contentButton, $headline, ...$baseContent } = $content ?? {};

  return (
    <ClassNameProvider value={$base}>
      <ShowHero {...$base} {...props}>
        <ShowHero.Content {...$baseContent}>
          {category?.value && category.url && (
            <ShowHero.Content.Category {...$category}>
              <Tag
                value={category?.value}
                link={{ href: category?.url || `etikett/${category?.value}` }}
                options={mergeOptions(
                  {
                    variant: 'text',
                    size: 'video',
                    colors: 'alternative',
                  },
                  $categoryTag,
                )}
              />
            </ShowHero.Content.Category>
          )}
          {headline && <ShowHero.Content.Headline {...$headline}>{headline}</ShowHero.Content.Headline>}
          {button && (
            <ShowHero.Content.Button {...contentButton}>
              <Button
                link={{ href: button.url }}
                content={
                  <>
                    <Icon
                      name="arrowFilled"
                      options={mergeOptions(
                        {
                          size: 'none',
                          className: 'w-3.5 h-3.5',
                        },
                        $playIcon,
                      )}
                    />
                    {button.value}
                  </>
                }
                {...$button}
              />
            </ShowHero.Content.Button>
          )}
          {description && <ShowHero.Content.Description {...$description}>{description} </ShowHero.Content.Description>}
        </ShowHero.Content>
        {image && (
          <ShowHero.Image {...$image}>
            <NextImage priority src={image.src || ''} alt={image.alt || ''} fill {...$nextImage} />
          </ShowHero.Image>
        )}
      </ShowHero>
    </ClassNameProvider>
  );
};
export default StandaloneShowHero;
