import { ShowsSlider } from '@/components/ShowsSlider';
import { StandaloneArticleFooter } from 'base/components/ArticleFooter';
import type { Show } from 'base/components/ShowsSlider';
import { getAllShowsPageData } from 'lib/data/video';
import mergeProps from 'lib/utils/mergeProps';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const SvenskdamStandaloneArticleFooter: typeof StandaloneArticleFooter = (props) => {
  const [shows, setShows] = useState<Show[]>([]);

  const fetchShows = async () => {
    const data = await getAllShowsPageData();

    const shows =
      data?.shows?.playlist?.map<Show>((playlist) => ({
        description: playlist.description,
        src: playlist.image,
        playlistId: playlist.mediaId,
        title: playlist.title,
        url: playlist.url,
      })) ?? [];

    setShows(shows);
  };

  useEffectOnce(() => {
    fetchShows();
  });

  return (
    <>
      <StandaloneArticleFooter
        {...mergeProps(
          {
            options: {
              $tag: {
                options: {
                  size: 'small',
                },
              },
            },
          },
          props,
        )}
      />
      <ShowsSlider shows={shows} />
    </>
  );
};
