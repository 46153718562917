import { useClassName } from '@/styles/ShowHero';
import { PropsFromSubcomponents } from '@/types/component';
import { GenericComponentFactory } from 'base/components/GenericComponent';

export type ShowHeroSubcomponentProps = PropsFromSubcomponents<typeof ShowHero>;

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'section' });
const Content = GenericComponent({ as: 'div', styles: { key: 'content' } });
const Headline = GenericComponent({ as: 'h1', styles: { key: 'headline' } });
const Category = GenericComponent({ as: 'span', styles: { key: 'category' } });
const Button = GenericComponent({ as: 'span', styles: { key: 'button' } });
const Description = GenericComponent({ as: 'p', styles: { key: 'description' } });
const Image = GenericComponent({ as: 'div', styles: { key: 'image' } });

export const ShowHero = Object.assign(Base, {
  Content: Object.assign(Content, {
    Headline,
    Category,
    Button,
    Description,
  }),
  Image,
});
