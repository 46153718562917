/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneShowHero as ResolvedStandaloneShowHero } from 'base/components/standalone/ShowHero';

export const ShowHero = ResolvedStandaloneShowHero;

export type ShowHeroProps = PropsFromComponent<typeof ShowHero>;

export const ShowHeroWith = (extras: DynamicStandaloneExtras): typeof ShowHero => {
    return function ShowHero(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ShowHero');
        return <ResolvedStandaloneShowHero {...mergeProps({ options: { theme } }, props)} />;
    }
}